import React from 'react';
import { FlechaArribaIcono, SubirArchivoIcono } from '../../../../util/IconosSvg';
import { convertirTamanoArchivo } from '../../../../util/Funciones';
import { read, utils } from "xlsx";
import dayjs from 'dayjs';
import { 
  FORMATO_FECHA_MOSTRAR, 
  POCISION_INICIAL_DEFECTO, 
  POCISION_NOMINA, 
  POCISION_REGION, 
  POCISION_REGION_COMUNA 
} from '../../../../constants/constantes';

const cargarNominaCovid = (objEvento, guardarArchivo) => {

  const objArchivoCargado = objEvento.target.files[POCISION_INICIAL_DEFECTO];
  const objLeerArchivo    = new FileReader();
  objLeerArchivo.onload   = (objEvento) => {

      const objContenido = read(objEvento.target.result);
      const lstHoja      = objContenido.SheetNames;

      if (lstHoja.length) {
        let lstCabecera        = utils.sheet_to_json(objContenido.Sheets[lstHoja[POCISION_NOMINA]], {header:1})[POCISION_INICIAL_DEFECTO];
        let lstFila            = utils.sheet_to_json(objContenido.Sheets[lstHoja[POCISION_NOMINA]], {raw:false});
        let lstRegionComuna    = utils.sheet_to_json(objContenido.Sheets[lstHoja[POCISION_REGION_COMUNA]]);
        let lstRegion          = lstRegionComuna.map(objElemento => objElemento[POCISION_REGION]).filter(objElemento => objElemento);
        let lstRegionConComuna = [];

        lstRegion.forEach(objRegion => {
          lstRegionConComuna.push({[objRegion]: lstRegionComuna.map(objElemento => objElemento[objRegion]).filter(objElemento => objElemento)});
        });

        let objArchivo = {
          nombre: objArchivoCargado.name,
          tamano: convertirTamanoArchivo(objArchivoCargado.size),
          archivo: lstFila,
          cabeceras: lstCabecera,
          fecha: dayjs().format(FORMATO_FECHA_MOSTRAR),
          archivoCargado: true,
          regionConComunas: lstRegionConComuna,
        }
        guardarArchivo(objArchivo);
      }
  }
  objLeerArchivo.readAsArrayBuffer(objArchivoCargado);
  objEvento.target.value = '';
}

export const NominaSeleccionar = ({ objArchivo, guardarArchivo }) => {

  let strIcoSubirArchivo = "cargar_icono--naranja";
  let strBtnSubirArchivo = "btn--naranja";

  if (objArchivo.archivoCargado) {
    strIcoSubirArchivo = "cargar_icono--gris";
    strBtnSubirArchivo = "btn--gris";
  }

  return (
    <section className="cargar">
      <span className={`cargar_icono ${strIcoSubirArchivo}`} >
        <SubirArchivoIcono />
        <FlechaArribaIcono />
      </span>
      <button className={`cargar_btn ${strBtnSubirArchivo}`} >
        <input 
          accept=".xlsx"
          type="file" 
          className="cargar_input" 
          onChange={(objEvento)=>{cargarNominaCovid(objEvento, guardarArchivo)}} 
          />
        Cargar Nómina
      </button>
    </section>
  )
}
