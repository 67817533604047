import { NominaIcono } from "../../../../util/IconosSvg";
import { NominaEjemplo } from "./NominaEjemplo";
import { NominaCargar } from "./NominaCargar";
import { useArchivoCargado } from "../../../../reducer/Hooks/useArchivoCargado";
import { Button, Form } from "react-bootstrap";
import { useContext, useState } from "react";
import { ConfiguracionContexto } from "../../../../reducer/Configuracion/ConfiguracionContexto";
import { ContratanteContexto } from "../../../../reducer/Contratante/ContratanteContexto";
import { validarCorreoElectronico } from "../../../../util/Funciones";
import { mostrarNotificacion } from "../../../../util/Notificacion";
import { crearCliente, crearSolicitudPago } from "../../../../service/ConfiguracionService";
import { 
  NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES,
  NOTIFICACION_ERROR_CORREO, 
  NOTIFICACION_ERROR_RUT, 
  NOTIFICACION_ERROR_RUT_CORREO, 
  NOTIFICACION_TIPO_ERROR, 
  TEXTO_FECHA, 
  TEXTO_NOMBRE_DOCUMENTO, 
  TEXTO_TAMANO,
  VALOR_ESPACIO,
  URL_PAGO_COMPLEMENTO,
  FORMATO_PESO_CHILENO,
  DECIMALES_CLP,
  VALOR_TAMANO_UNO,
  NOTIFICACION_ERROR_PAGO_TOKU,
  CODIGO_PRODUCTO
} from "../../../../constants/constantes";
import { Cargando } from "../../../../util/Cargando";

const { validate } = require("rut.js");

const archivoCargadoInicializacion = {
  nombre: TEXTO_NOMBRE_DOCUMENTO,
  tamano: TEXTO_TAMANO,
  archivo: null,
  cabeceras: null,
  fecha: TEXTO_FECHA,
  archivoCargado: false,
  regionConComunas: [],
  errores: null,
  hayError: false,
  verificado: false,
  declaracion: false
}

const ejecutarProceso = async (archivo, precio, formulario, guardarNomina, actualizarNomina, setBlnRealizandoPago) => {
  
  let objCliente= {
    "envia_mail": false,
    "id_cliente": formulario.rut.replace("-",""),
    "correo": formulario.correo,
    "nombre": archivo.archivo[0].EmpresaRazonSocial
  }

  await crearCliente(objCliente).then(async (objRespuesta) => {

    ejecutarPago(archivo, precio, formulario, guardarNomina, setBlnRealizandoPago, objRespuesta.id, actualizarNomina)
  }).catch((objError)=>{
    console.error(objError);
    mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_PAGO_TOKU);
  });
}

const ejecutarPago = async (archivo, precio, formulario, guardarNomina, setBlnRealizandoPago, idCliente, actualizarNomina) => {

  if (archivo.verificado && !archivo.hayError) {

    setBlnRealizandoPago(true);
    let intNominaTamano         = Number(archivo.archivo.length);
    let blnRutValido            = validate(formulario.rut);
    let blnCorreoValido         = validarCorreoElectronico(formulario.correo);
    let blnTrabajadoresCantidad = Number(formulario.trabajadoresCantidad) === intNominaTamano;

    if (blnRutValido && blnCorreoValido && blnTrabajadoresCantidad) {

      let objPagoCovid = {
        idCliente: formulario.rut,
        rut: formulario.rut, 
        correo: formulario.correo, 
        trabajadoresCantidad: intNominaTamano, 
        montoPago: (intNominaTamano * precio.precioBaseUF * precio.valorUF * formulario.trabajadoresCantidad).toFixed(DECIMALES_CLP),
        nominas : [...archivo.archivo],
        aceptaDeclaracion: archivo.declaracion
      }

      let objReferenciaNomina = await guardarNomina(objPagoCovid);

      await ejecutarSolicitudPago(idCliente, objReferenciaNomina, precio, actualizarNomina, formulario);
      setBlnRealizandoPago(false);
    }
    else{
      if (!blnRutValido && !blnCorreoValido && !blnTrabajadoresCantidad) {
        let strMensaje = NOTIFICACION_ERROR_RUT_CORREO+VALOR_ESPACIO+NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES;
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, strMensaje);
        return null;
      }

      if (!blnRutValido && !blnCorreoValido ) {
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_RUT_CORREO);
        return null;
      }

      if (!blnRutValido) {
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_RUT);
        return null;
      }

      if (!blnCorreoValido) {
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CORREO);
        return null;
      }
      if (!blnTrabajadoresCantidad){
        mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CANTIDAD_TRABAJADORES);
        return null;
      }
    }
  }
}

const ejecutarSolicitudPago = async (idcliente, objReferenciaNomina, precio, actualizarNomina, formulario) => {
  
  let datFechaHoy    = new Date();
  let intAnio        = datFechaHoy.toLocaleString("default", { year: "numeric" });
  let intMes         = datFechaHoy.toLocaleString("default", { month: "2-digit" });
  let intDia         = datFechaHoy.toLocaleString("default", { day: "2-digit" });
  let datFechaPago   = intAnio + "-" + intMes + "-" + intDia;
  let datFechaCodigo = intAnio+intMes+intDia+datFechaHoy.getHours()+datFechaHoy.getMinutes()+datFechaHoy.getSeconds();

  let objCliente = {
    "monto_pago": Number(precio.precioBaseCLP * formulario.trabajadoresCantidad).toFixed(DECIMALES_CLP),
    "codigo_cliente": idcliente,   
    "codigo_producto": CODIGO_PRODUCTO+datFechaCodigo,
    "fecha_pago": datFechaPago,
    "tipo_divisa": FORMATO_PESO_CHILENO
  }

  await crearSolicitudPago(objCliente).then(async (objRespuesta) => {

    await actualizarNomina(objReferenciaNomina._key.path.segments[VALOR_TAMANO_UNO], {idFactura : objRespuesta.id });
    window.location.href = objRespuesta.link_payment+URL_PAGO_COMPLEMENTO;
  }).catch((objError)=>{
    console.error(objError);
    mostrarNotificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_PAGO_TOKU);
  });
}

export const Nomina = () => {

  const [blnRealizandoPago, setBlnRealizandoPago] = useState(false);
  const {archivo, guardarArchivo, eliminarArchivo, guardarErrores } = useArchivoCargado(archivoCargadoInicializacion);
  const { precio, guardarNomina, actualizarNomina } = useContext(ConfiguracionContexto);
  const { formulario } = useContext(ContratanteContexto);

  if (blnRealizandoPago) {
    return (
      <Cargando
        claseColor="naranjo"
        idSpinner="spinnerNaranjo"
        posicionLogo="align-self-center w-100"
      />
    )
  }
  else{
    return (
      <>
      <section className="datos">
        <header className="datos_cabecera">
          <h6 className="datos_cabeceraTitulo">
            <NominaIcono />
            <span>Nómina de los colaboradores</span>
          </h6>
        </header>
        <ol className="seccionNomina">
          <li className="seccionNomina_elemento container-fluid">
            <NominaEjemplo />
          </li>
          <li className="seccionNomina_elemento container-fluid">
            <p className="seccionNomina_titulo">Carga la nómina de colaboradores</p>
            <NominaCargar archivo={archivo} guardarArchivo={guardarArchivo} eliminarArchivo={eliminarArchivo} guardarErrores={guardarErrores} />
          </li>
        </ol>
        <section className="declaracion">
          <Form.Check 
            type="switch"
            id="declaracion"
            label="Declaro que las personas identificadas en la nómina adjunta son trabajadores con contrato de trabajo vigente de la empresa solicitante"
            checked={archivo.declaracion}
            onChange={()=>{ 
              guardarArchivo({ declaracion: !archivo.declaracion });
            }}
          />
        </section>
      </section>
      { archivo.verificado && !archivo.hayError && archivo.declaracion && (
        <section className="pago">
          <Button
            variant="light" 
            className={`pago_btn `}
            onClick={()=>{ejecutarProceso(archivo, precio, formulario, guardarNomina, actualizarNomina, setBlnRealizandoPago)}}
            > Pagar </Button>
        </section>
      )}
      </>
    ) 
  }
}